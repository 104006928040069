import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';

function Breadcrumb({ breadcrumbs }) {
  const breadcrumbsWithoutLast = breadcrumbs.slice(0, -1);
  return (
    <nav aria-label="breadcrumb" className='bread-container container'>
      <ol className="breadcrumb-list">
        {breadcrumbs.map((breadcrumb, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}
          >
            {index !== breadcrumbs.length - 1 ? (
              <Link to={breadcrumb.path} state={{ breadcrumb: breadcrumbsWithoutLast }}>{breadcrumb.name}</Link>
            ) : (
              breadcrumb.name
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
