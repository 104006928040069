import React from 'react'
import Header from '../Components/Header';
import BannerSection from '../Components/BannerSection';
import CatlogSlider from '../Components/CatlogSlider';
import NewArrival from '../Components/NewArrival';
import Trusted from '../Components/Trusted';
import Footer from '../Components/Footer';
import PostYourRequirment from '../Components/PostYourRequirment';
import PopularCategory from '../Components/PopularCategory';
import Intro from './Intro';

function App() {
return (
    <div >
    {/* <Intro/> */}
<Header/>


<div className='top-banner-container container'>
<BannerSection/>
</div>

{ <div className='popular-category-row'>
    <div className='container'>
    <PopularCategory/>      
    </div>
</div> }


<div className='enquiry-row'>
    <div className='container'>
    <PostYourRequirment/> 
    </div>
</div>


<div className='w-full mt-4 shadow rounded-lg drop-shadow-[0px 2px 30px 9px rgba(0, 0, 0, 0.1)] p-8 lg:max-w-7xl lg:px-4 xl:max-w-[1600px] mx-auto'>
<h3 className='text-left text-[18px] font-poppins text-[#082f4a] font-[500]'>Trending Categories</h3>
<CatlogSlider/>
</div>



<div>

</div>

<Footer/>




 </div>
)

}

export default App;
