import React from 'react';
import rightBanner from '../assests/img/right-banner.jpg';
import leftBanner6 from '../assests/img/slide-01.jpeg';
import leftBanner5 from '../assests/img/slide-02.jpeg';
import leftBanner4 from '../assests/img/slide-03.jpeg';
import leftBanner3 from '../assests/img/slide-04.jpeg';
import leftBanner2 from '../assests/img/slide-05.jpeg';
import leftBanner1 from '../assests/img/left-banner.jpg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CategoryList from './CategoryList';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const BannerSection = () => {
  return (
    <div>
      <div className="grid gap-4 grid-cols-12 banner-grid">
        <div className="col-span-2 relative z-[998] left-cat-home">
          <CategoryList/>
        </div>
        <div className='col-span-10 relative z-[0] right-banner-home'>
          <div className='grid grid-cols-12 gap-4 slider-inner-container'>
      
          <div className="w-full col-span-8 main-top-slider">
            <Carousel responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={2000}
             infinite={true}>
              
              <div> <img src={leftBanner1} alt="Logo" className="w-full" /></div>
              <div> <img src={leftBanner2} alt="Logo" className="w-full" /></div>
              <div> <img src={leftBanner3} alt="Logo" className="w-full" /></div>
              <div> <img src={leftBanner4} alt="Logo" className="w-full" /></div>
              <div> <img src={leftBanner5} alt="Logo" className="w-full" /></div>         
              <div> <img src={leftBanner6} alt="Logo" className="w-full" /></div>         
            </Carousel>
           
          </div>
        
        <div className=' w-full col-span-4 '>

        <div className='right-banner-top'>
        <img src={rightBanner} alt="Logo" />
        </div>
        
       
      
         
        
        </div>
        </div>
       
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
