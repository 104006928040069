import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ProductList from '../Components/ProductList'
import ProductShow from '../Components/ProductShow'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../Components/Breadcrumb';
import { useLocation } from 'react-router-dom';


function App() {
  const { slug } = useParams();
  console.log(`slug: ${slug}`);
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = React.useState([]);
  console.log('breadcrumb 16:', location.state);

  useEffect(() => {
   
   
  }, [breadcrumb, slug])

  const _getBreadCrumbs = (data) => {
    const breadcrumbs = [
      { name: 'Home', path: '/' },
      { name: data.name, path: `/products/${data.slug}` }
    ];
    setBreadcrumb(breadcrumbs);
  }

  return (
    <div>
      <div>
        <Header />
       {breadcrumb.length > 0 && (<Breadcrumb breadcrumbs={breadcrumb} />)} 
        <div className='mx-auto lg:max-w-7xl lg:px-4 xl:max-w-[1600px]'>
          <ProductList slug={slug} breadcrumbs= {breadcrumb} onCategoryDataGet= {(data)=> _getBreadCrumbs(data)} />
        </div>
        <Footer />
      </div>
    </div>
  )

}

export default App;
