import React, { useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { CiHome, CiPhone, CiAt } from "react-icons/ci";
import { set } from 'lodash';

function Contact() {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    subject: '',
    message: ''
  });
  

  // State for validation errors
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [afterSubmitText, setAfterSubmitText] = useState('');

  // Handle input change
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  // Validate form fields
  const validate = () => {
    let validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid";
    }
    if (!formData.subject) {
      validationErrors.subject = "Subject is required";
    }
    if (!formData.message) {
      validationErrors.message = "Message is required";
    }
    return validationErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    setAfterSubmitText('');

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const url = 'https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/contactus';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      console.log('Form Data Submitted: ', formData);
      const data = await response.json();
      console.log('Response: ', data);
      setLoading(false);
      if(data.status == true){
        setFormData({
          name: '',
          email: '',
          mobile_number: '',
          subject: '',
          message: ''
        });
        setAfterSubmitText('Thank you for your message. We will get back to you soon.');

      }
    else 
      alert('Message not sent!');
    }
  };

  return (
    <div>
      <Header />
      <div className='mx-auto container'>
        <h2 className='page-title'>Contact Us</h2>
        <div className='cont-inner-container'>
        
         

          <div className='go-map-box'>

          <div className='con-info-box'>
          <div className='con-box'>
          <div className='icon-box'>
          <CiHome className='con-icon' />
          </div>
          
            <h3>Office Location</h3>
            <div className='add-info'>
              Rua de Dom Aleixo Corte Real, Fomento-Bebora, Dili, Timor-Leste
            </div>
          </div>

          <div className='con-box'>
          <div className='icon-box'>
          <CiPhone className='con-icon' />
          </div>
          
            <h3>Call Us</h3>
            <div className='add-info'>
              <a href="tel:+670 3310455">+670 3310455</a>
            </div>
          </div>

          <div className='con-box'>
          <div className='icon-box'>
          <CiAt className='con-icon' />
          </div>
          
            <h3>Email Address</h3>
            <div className='add-info'>
              <a href="mailto:info@mci.gov.tl">info@mci.gov.tl</a>
            </div>
          </div>

          </div>

            {/* <div className='map-box'>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.4265519794717!2d125.56449367501325!3d-8.554916791488692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d01e7876aa61faf%3A0x8820b97cabec2a57!2sEdificio%20de%20Fomento!5e0!3m2!1sen!2sin!4v1726921941407!5m2!1sen!2sin"
                width="600"
                height="392"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}

            <div className='cont-form-box'>
              <section className="inner-form-box">
              <h3>Get in Touch Now</h3>
              <p>Need personalized advice? Our dedicated team is here to assist you. Reach out today for expert support and tailored solutions to meet your needs.</p>
                <div className="">
                  <form onSubmit={handleSubmit} className="cont-form">
                    <div>
                      <label htmlFor="name" className="sr-only block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className={`block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border ${errors.name ? 'border-red-500' : 'border-gray-300'} shadow-sm`}
                        placeholder="Your Name"
                        required
                      />
                      {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                    </div>

                    <div>
                      <label htmlFor="email" className="sr-only block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={`shadow-sm bg-gray-50 border ${errors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                        placeholder="Your Email"
                        required
                      />
                      {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                    </div>

                    <div>
                      <label htmlFor="phone" className="sr-only block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phone Number</label>
                      <input
                        type="text"
                        id="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleInputChange}
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm"
                        placeholder="Phone Number"
                      />
                    </div>

                    <div>
                      <label htmlFor="subject" className="sr-only block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                      <input
                        type="text"
                        id="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        className={`block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border ${errors.subject ? 'border-red-500' : 'border-gray-300'} shadow-sm`}
                        placeholder="Subject"
                        required
                      />
                      {errors.subject && <p className="text-red-500 text-sm">{errors.subject}</p>}
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="message" className="sr-only block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                      <textarea
                        id="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="6"
                        className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Leave a comment..."
                      ></textarea>
                      {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                    </div>

                    <div className="sm:col-span-2">
                    {afterSubmitText && <p className="text-green-500 text-sm">{afterSubmitText}</p>}
                    </div>
                    <button
                      type="submit"
                      disabled={loading}
                      className={`contact-btn py-3 px-5 text-sm font-medium text-center text-white rounded-lg ${loading ? 'bg-gray-500' : 'bg-primary-500'}`}
                      >
                      {loading ? 'Sending...' : 'Send Message'}
                    </button>
                  </form>
                </div>
              </section>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
