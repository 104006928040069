import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaPhone, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import InquiryDialog from './InquireyDialog';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import ReactImageGallery from "react-image-gallery";
import trustLogo from '../assests/img/t-logo.png';



function ProductDetail(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProductData] = useState();
  const user = localStorage.getItem('user');
  const userDetails = user ? JSON.parse(user) : null;
  const token = localStorage.getItem('token');

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    let productDetailUrl = 'https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/productDetails';
    if (props.slug) {
      productDetailUrl = productDetailUrl + `?product_slug=${props.slug}`;
    }
    fetch(productDetailUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setData(data.data || {}); // Set a default empty object if no data
        document.title = data.data.product_name + ' | Made in TimorLeste';
        setLoading(false);
        console.log(data);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });

      return () => {
        document.title = 'Made In Timor-Leste'; 
      };
  }, [props.slug]);

  const checkIfUserIsLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  };

  const handleEnquiryClick = (product) => {
    const isLoggedIn = checkIfUserIsLoggedIn();
    if (isLoggedIn) {
      setCurrentProductData(product);
      onOpenModal();
    } else {
      navigate('/login', { state: { from: window.location.pathname, product } });
    }
  };

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const imageList = [{ original: data.product_image_url, thumbnail: data.product_image_url }, ...data.gallery_image.map((e)=> {
    return {
      original: e,
      thumbnail: e
    }
  })];


  return (
    <div className="product-detail-content container">
      <ul className='post'>
        {
          <li key={data.slug} className='w-full'>
            <div className='product-detail-containter'>
            <div className='product-media'>
            <ReactImageGallery items={imageList} />
            </div>
            

              <div className='product-info'>
                <div className='product-detail-left'>
                  <h3 className='product-title-name-inner'>{data.product_name}</h3>
                  {!token && (
                    <>
                    <h4 className='price-box'>  <Link to="/login" className='btn btn-latest-price'>Get Latest Price</Link></h4>
                    </> 
                  )}

                  {userDetails && (
                    <h4 className='price-box'><a href="#" className='btn btn-latest-price'>$ {data.product_price}</a></h4>
                  )}

                  <div className='prod-attri-box'>
                    <h2>Product Details</h2>
                    <div className="attribute-box tabledesc mt10">
                      <table>
                        {data.attributes && data.attributes.length > 0 && (   // Add check for attributes
                          <tbody>
                            {data.attributes.map((item, index) => (
                              <tr key={index}><td className="tdwdt">{item.name}</td><td className="tdwdt1 color6"><div className="dsf"><span>{item.option}</span></div></td></tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>

                  <div className="seller-row">
                    <div className='contact-seller-row'>
                      <button onClick={() => handleEnquiryClick(data)} className='send-inq-btn'>
                        <CiMail />
                        Contact Now
                      </button>

                      <InquiryDialog open={open} onCloseModal={onCloseModal} title={data.product_name} currentProduct={currentProduct} />

                      {!token && (
                        <>
                          <button className='view-number-btn'>
                            <Link to="/login"> <CiPhone />View Number</Link>
                          </button>
                        </>
                      )}

                      {userDetails && (
                        <Link to={`tel:${data.phone_number}`} className='view-number-btn flex align-center'>
                          <FaPhoneAlt className='text-red text-[20px] font-semibold' />{data.phone_number}
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className='short-description-box' dangerouslySetInnerHTML={{ __html: data.product_short_description }} />
                </div>
              </div>
            </div>
          </li>
        }
      </ul>

      <div className='pro-tab-container container'>
        <div className='tab-container'>
          <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Product Description</Tab>
              <Tab>Details Photos</Tab>
            </TabList>

            <TabPanel>
              <div className='tab-description-content' dangerouslySetInnerHTML={{ __html: data.product_description }} />
            </TabPanel>

            <TabPanel>
              <div className="attribute-box tabledesc mt10">
                <table>
                  {data.attributes && data.attributes.length > 0 && (  // Add check for attributes
                    <tbody>
                      {data.attributes.map((item, index) => (
                        <tr key={index}><td className="tdwdt">{item.name}</td><td className="tdwdt1 color6"><div className="dsf"><span>{item.option}</span></div></td></tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </TabPanel>

            <TabPanel>
              <div className='details-photo'>
                <ul>
                  {data.gallery_image && data.gallery_image.length > 0 && (  // Add check for gallery_image
                    data.gallery_image.map((item, index) => (
                      <li key={index}><img onClick={() => openImageInNewTab(item)} src={item} className='pro-thumb' alt={`gallery-${index}`} /> </li>
                    ))
                  )}
                </ul>
              </div>
            </TabPanel>
          </Tabs>
        </div>

        <div className='seller-box'>
          <a href="#" className='seller-name flex'><img src={data.logo} alt="seller-logo" /><span>{data.store_name}</span></a>
          <div className='seller-address'> {data.address_1} {data.address_2} {data.city} {data.postcode} {data.country}</div>
          <div className='gst-number'>GST/TIN - {data.tin_number}</div>
          <div className='trust-seller'>
            <img src={trustLogo} alt="trusted-seller" />
            <span className="trusted-text"><strong>Trusted</strong>Seller</span>
          </div>

          <div className='contact-seller-row'>
            <button onClick={() => handleEnquiryClick(data)} className='send-inq-btn'>Send Inquiry</button>

            <InquiryDialog open={open} onCloseModal={onCloseModal} title={data.product_name} currentProduct={currentProduct} />

            {!token && (
              <button className='view-number-btn'>
                <Link to="/login">View Number</Link>
              </button>
            )}

            {userDetails && (
              <Link to={`tel:${data.phone_number}`} className='view-number-btn flex align-center'>
                <FaPhoneAlt className='text-red text-[20px] font-semibold' />{data.phone_number}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
