import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Login from './Login';
import axios from 'axios';


const EmailVerificationSend = () => {


    return (
        <>
            <div className='flex items-center flex-col w-full bg-sky-950 h-[100vh] justify-center '>
            <h1 className='text-white font-bold text-3xl mt-10' >Email verfication link has been sent to your email, please verify and login again</h1>

            </div>
        </>
    )
}

export default EmailVerificationSend