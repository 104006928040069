import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'


function About() {
return (
    <div>
  <div>   
      <Header/>
    <div className='about-container container'>
      {/* <h2 className='page-title'>About Us</h2> */}
      <h3 className='sub-page-title'>Who are we?</h3> 
      <p>We are a part of Ministry of Commerce and Industry initiative to promote local products.</p>
      <h3 className='sub-page-title'>We Provide</h3> 
      <ul>
          <li>• One stop place where each seller gets their own space to showcase their products, without investing in high end technology individually.</li>
      <li>• a marketing portal with numerous opportunities to improve an organization's performance</li>
    <li>• wider reachability </li>
      </ul>
      <h3 className='sub-page-title'>Benefits</h3> 
      <ul>
        <li>• Cost Efficiency: Reduces operational and transaction costs for businesses.</li>
        <li> • Enhanced Connectivity: Strengthens relationships and communications within the business ecosystem.</li>
        <li> • Trade portals provide a platform for local products to reach a wider network of businesses and retailers in different regions and countries.</li>
        <li>  • Local producers can forge partnerships with distributors and businesses through B2B portals, enhancing their market presence.</li>
      </ul> 
      <h3 className='sub-page-title'>About Us</h3> 
      <p>Welcome to our B2B Portal for Timor-Leste, the premier online platform designed to connect businesses, promote trade, and foster collaboration in the vibrant and emerging market of Timor-Leste. </p>
      <p>At our core, we believe in empowering businesses, both large and small, by providing them with the tools and resources they need to thrive in today's competitive landscape. Our portal serves as a bridge to facilitate meaningful connections between local and international companies, enabling them to reach new markets, discover partnerships, and enhance their growth potential.</p>
      <h3 className='sub-page-title'>Our Vision</h3> 
      <p>We envision a robust business community in Timor-Leste where companies can easily exchange ideas, share resources, and collaborate on projects that spur innovation and development. By leveraging the power of technology, we aim to create a seamless experience that simplifies the way businesses interact with each other.</p>
      <h3 className='sub-page-title'>Our Mission</h3> 
      <p>Our mission is to support the growth of the Timorese economy by providing a comprehensive platform that promotes transparency, efficiency, and accessibility in business transactions. We strive to empower entrepreneurs, foster sustainable practices, and encourage international trade to enhance the business landscape of Timor-Leste.</p>
      <h3 className='sub-page-title'>What We Offer</h3> 
      <ul>
        <li> • Business Listings: An extensive directory of businesses across various sectors in Timor-Leste, allowing users to easily find potential partners and suppliers.</li>
        <li>• Networking Opportunities: Tools and resources to help businesses connect, collaborate, and engage with each other through forums, events, and partnerships.</li>
        <li>• Market Insights: Access to valuable information about the local market, industry trends, and best practices to help businesses make informed decisions.</li>
        <li>• Resource Center: A wealth of guides, articles, and resources aimed at supporting business development and helping companies navigate challenges in the marketplace.</li>
      </ul>
      <p>Join us today and become a part of the thriving business ecosystem in Timor-Leste. Together, we can build a brighter future for businesses and communities across the country.</p>     
      <span className='eye-catch'>"Let's connect and grow while contributing to the economic development of Timor-Leste."<br/>
      <span className='author-name'>H. E Nino Pereira <span className='smal-pos'>(Minister of Commerce and Industry, Timor Leste)</span> </span></span>
    
    </div>
      <Footer/>
    </div>
    </div>
)

}

export default About;
