import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../assests/img/logo.png';
import { Link } from 'react-router-dom';
import { countryWithCodes } from '../Utils/Constants';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import loginBanner from '../assests/img/login-advt.jpg';
import NewArrival from '../Components/NewArrival';


const Register = () => {

    
    const navigate = useNavigate();
    const [input, setInput] = useState({
        first_name: "",
        last_name: "",
        pin: "",
        email: "",
        password: "",
        confirmPassword: "",
        mobile:'',
        country_code: ''
    });
    

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(''); 


    console.log(input)

    // to store value in localstorage

    const handelSubmit = async (e) => {
        e.preventDefault();
        setErrorText('');
        if(input.first_name == "" || input.last_name == "" || input.pin == "" || input.email == "" || input.password == "" || input.mobile == "" || input.country_code == "") {
            setErrorText('Please fill all the fields');
            return;
        }
        if(input.password.length < 6){
            setErrorText('Password must be at least 6 characters');
            return;
        }
        if(input.mobile.length < 10){
            setErrorText('Mobile number must be at least 10 characters');
            return;
        }
        if(input.password !== input.confirmPassword){
            setErrorText('Password and confirm password must be same');
            return;
        }

        if(loading)return;
        setLoading(true);
        const registerUrl = `https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/customercreate?email=${encodeURIComponent(input.email)}&password=${encodeURIComponent(input.password)}&first_name=${input.first_name}&last_name=${input.last_name}&mobile_number=${input.mobile}&pincode=${input.pin}&country_code=${input.country_code}`;
        var response = await fetch(registerUrl);
        var data = await response.json();
        console.log(data);
        if(data.status == 'true'){
        //   const headers = {
        //     'Authorization': data.data.token
        //   }
        //   console.log('headers', headers);
        //   var customerDetailsResponse = await fetch(`https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/customer_details`,{headers: headers} );
        //   var customerDetails = await customerDetailsResponse.json();
        //   if(customerDetails.message == 'Success'){
        //     localStorage.setItem('user', JSON.stringify(customerDetails.data));
        //     localStorage.setItem('token', data.data.token);
        //     navigate("/")
        //   }else{
        //     alert("something went wrong please try again!")
        //   }

        navigate("/email-verification-send");
        
        }else {
          setErrorText(data.message);
        }
        setLoading(false);

    }

    return (
        <>
        <Header/>
           
            <div className='login-container'>
                <div className='login-form-box'>
                <form onSubmit={handelSubmit} className='flex flex-col items-center gap-7'>
                        <h1 className='login-title'>Sign Up Now !</h1>
                        
                        <input name='first_name' value={input.first_name} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="text" placeholder='Enter your first name' className='input-box' />

                        <input name='last_name' value={input.last_name} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="text" placeholder='Enter your last name' className='input-box' />

                        <input name='mobile' value={input.mobile} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="number" placeholder='Enter your mobile number' className='input-box' />

                        <input name='pin' value={input.pin} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="number" placeholder='Enter your PIN Code' className='input-box' />

                        <select name='country_code' value={input.country_code} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} className='input-box'>
                            {
                             
                                <>
                                <option value=''>Select Country</option>
                                {countryWithCodes.map((country, index) => (
                                    <option key={index} value={country.code}>{country.name} ({country.dial_code})</option>
                                ))}

                                </>
                              
                            }
                        </select>

                        <input name='email' value={input.email} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="email" placeholder='Enter your email' className='input-box' />

                        <input name='password' value={input.password} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="Password" placeholder='Enter your password' className='input-box' />

                        <input name='confirmPassword' value={input.confirmPassword} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="Password" placeholder='Confirm your password' className='input-box' />

                        <span className='text-red-500'>{errorText}</span>

                        <button type='submit' className='login-btn'>{loading ?'loading..' : 'Register'}</button>
                        <span className='register-link-box'>Already have an account <Link to="/login" className=''>Login</Link></span>
                    </form>
                </div>

                <div className='login-advt'>
                <NewArrival/>
        </div>
            </div>
               
          
            <Footer/>
        </>
    )
}

export default Register