import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Login from './Login';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);


}

const EmailVerification = () => {


    const navigate = useNavigate();

    const [text, setText] = useState('loading');


    const query = useQuery();


    const key = query.get('key');
    const userId = query.get('userId');

    useEffect(() => {
        if (key && userId) {
            verifyEmail();
        }else{
            setText('Invalid Link');
        }
    }, [key, userId]);

    const verifyEmail = async () => {
        try {
            const url = `https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/email_verification?user_id=${userId}&key=${key}`;
            const response = await axios.get(url);
            setText(response.data.message);
        } catch (error) {
            console.log(error);
            setText('Something went wrong');
        }
    }

    const onNavigate = () => {
        navigate('/login');
    }

    
    return (
        <>
            <div className='flex items-center flex-col w-full bg-sky-950 h-[100vh] justify-center '>
            <h1 className='text-white font-bold text-3xl mt-10' >{text}</h1>
            <br />
            {text !== 'loading' && <button type='submit' onClick={onNavigate} className='bg-green-600 w-[10%] h-8 rounded-md text-white font-bold'>Login</button>}

            </div>
        </>
    )
}

export default EmailVerification