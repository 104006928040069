import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import footlogo1 from '../assests/img/foot-logo-1.jpg';
import footlogo2 from '../assests/img/foot-logo-2.jpg';

const Footer = () => {
  return (
    <div className='main-footer'>    
    <div className='footer-container container'>
      <div className='py-8 mx-auto grid-flow-col  w-full grid grid-col-12 footer-inner-container'>
   
        <div className='col-span-2'>
        <div className='inner-footer-box'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>Customer Support</h3>
           <ul className='text-white text-[16px]   text-left'>
            <li > <Link to="/contact">Contact Us</Link></li>           
            <li ><Link to="/return">Return & Cancellation Policy</Link></li>
            <li ><Link to="/shipping">Shipping & Delivery Policy</Link></li>
           </ul>
        </div>       
        </div>  
       
        <div className='col-span-2'>
        <div className='inner-footer-box'>
        <h3 className='text-white text-[16px] mb-2 text-left'>For Sellers</h3>
           <ul className='text-white text-[16px] text-left'>
            <li>
            <a href="https://madeintimorleste.com/seller/vendor-register/">Register for Sellers</a></li>
            <li>
            <a href="https://madeintimorleste.com/seller/">Login for Sellers</a></li>
            </ul>
        </div>     
        </div>     


        <div className='col-span-2'>
        <div className='inner-footer-box'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>Useful Links</h3>
           <ul className='text-white text-[16px]   text-left'>
           <li><Link to="/about">About Us</Link></li>
            <li><a href="https://mci.gov.tl/" target="_blank">MCI Website</a></li>
            <li><a href="https://www.timor-lestetradeportal.com/en-gb" target="_blank">TIP</a></li>
            <li><Link to="/gallery">Gallery</Link></li>          
            </ul>
        </div>   
        </div>

        <div className='col-span-2'>
        <div className='inner-footer-box'>
        <h3 className='text-white text-[16px]   mb-2 text-left'>Signature</h3>
          <div className='foot-logo-box'>
            <img src={footlogo1}/>
            <img src={footlogo2}/>
          </div>
        </div>  
        </div>  
             
       
      </div>
      <hr className='w-full bg-[#ffffffa8] h-[1px]'/>
      <div className='w-full py-4 mx-auto'>
        <h3 className='text-[#ffffff] text-[16px] text-center'>1999-2024 Made in Timor-leste. All rights reserved.</h3></div>
    </div>
    </div>
  )
}

export default Footer
