import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "https://madeintimorleste.com/gallery/IMG_6268.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6268.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6269.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6269.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6270.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6270.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6272.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6272.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6273.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6273.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6274.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6274.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6275.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6275.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6276.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6276.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6277.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6277.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6278.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6278.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6279.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6279.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6280.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6280.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6281.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6281.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },

  {
    src: "https://madeintimorleste.com/gallery/IMG_6283.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6283.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },

  {
    src: "https://madeintimorleste.com/gallery/IMG_6284.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6284.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6289.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6289.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6290.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6290.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6293.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6293.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6294.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6294.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6295.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6295.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6298.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6298.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6302.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6302.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6305.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6305.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6306.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6306.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6307.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6307.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6311.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6311.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6313.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6313.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6314.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6314.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6315.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6315.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6316.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6316.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6317.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6317.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6328.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6328.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6329.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6329.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6330.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6330.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6331.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6331.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6332.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6332.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6333.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6333.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6335.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6335.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6337.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6337.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6338.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6338.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6343.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6343.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6345.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6345.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6347.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6347.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6348.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6348.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6350.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6350.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6358.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6358.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6359.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6359.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6361.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6361.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6381.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6381.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6382.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6382.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6385.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6385.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },
  {
    src: "https://madeintimorleste.com/gallery/IMG_6386.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6386.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6398.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6398.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6399.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6399.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6414.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6414.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6427.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6427.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6429.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6429.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6440.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6440.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6441.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6441.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6462.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6462.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6463.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6463.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6464.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6464.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6467.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6467.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6472.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6472.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6493.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6493.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6505.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6505.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6508.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6508.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6515.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6515.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6516.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6516.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6517.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6517.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6533.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6533.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6539.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6539.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6541.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6541.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6550.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6550.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  },{
    src: "https://madeintimorleste.com/gallery/IMG_6552.JPG",
    original: "https://madeintimorleste.com/gallery/IMG_6552.JPG",
    width: 300,
    height: 200,    
    caption: "Made in Timorleste",
  }
];
