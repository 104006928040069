import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assests/img/logo.png';
import { CiSearch } from "react-icons/ci";
import { CiBoxList } from "react-icons/ci";
import CategoryList from './CategoryList';
import { CiPower } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";
import { CiMenuFries } from "react-icons/ci";
import { CiCircleRemove } from "react-icons/ci";



import debounce from 'lodash/debounce';

const Header = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const user = localStorage.getItem('user');
  const userDetails = user ? JSON.parse(user) : null;
  console.log('user details', userDetails);
  const token = localStorage.getItem('token');

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu toggle
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State to track menu toggle

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  const fetchProducts = useCallback(
    debounce((query) => {
      if (query.length > 1) { // Trigger search after 2 characters
        const fetchProductsAsync = async () => {
          try {
            const response = await fetch(`https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/productserach?query=${query}`);
            const data = await response.json();

            const filteredResults = data.data?.filter(product =>
              product.title.toLowerCase().includes(query.toLowerCase())
            ) || [];

            setSearchResults(filteredResults);
            setIsDropdownVisible(true);
            console.log(filteredResults);
          } catch (error) {
            console.error('Error fetching products:', error);
            setIsDropdownVisible(false);
          }
        };

        fetchProductsAsync();
      } else {
        setSearchResults([]); // Clear results if search query is less than 2 characters
        setIsDropdownVisible(false);
      }
    }, 300), // Debounce delay of 300ms
    []
  );

  // Effect to call fetchProducts when searchQuery changes
  useEffect(() => {
    fetchProducts(searchQuery);
  }, [searchQuery, fetchProducts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onHandleClick = (product) => {
    console.log('product_clicked', product);
    navigate(`/product-details/${product.product_slug}`);
    setIsDropdownVisible(false);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen); // Toggle menu state
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close menu
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menu-open'); // Add class to body when menu is open
    } else {
      document.body.classList.remove('menu-open'); // Remove class from body when menu is closed
    }
  }, [isMenuOpen]); // Re-run effect when menu state changes


  useEffect(() => {
    if (isSearchOpen) {
      document.body.classList.add('search-open'); // Add class to body when menu is open
    } else {
      document.body.classList.remove('search-open'); // Remove class from body when menu is closed
    }
  }, [isSearchOpen]); // Re-run effect when menu state changes



  return (
    <div ref={dropdownRef}>
      <div className='main-header'>
        <div className='container header-container'>
          <div className='grid-cols-12 gap-4 grid header-grid'>
            <div className='col-span-2 text-left place-items-center flex logo-col'>
              <h3 className='text-md text-zinc-600'>
                <a href="/">
                  <img src={logo} alt="logo" className='logo-img' />
                </a>
              </h3>
              <button className='search-mobile-btn' onClick={toggleSearch}>
              <CiSearch className='search-icon-mobile' />
              </button>
              <button className='menu-btn' onClick={toggleMenu}>
              <CiMenuFries className="menu-toggle" />
              </button>
            </div>
            <div className='w-full flex gap-4 justify-start items-center col-span-7 search-col'>
              <div className="search-inner-box">
              <input
                id="search"
                name="search"
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for products, categories, and brands"
                className="block border-[1px] bg-white border-[#d1dae0] h-[50px] outline-0 rounded-md w-full py-2 pl-4 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
              <div className='bg-[#CF2532] search-icon-box rounded-r-lg flex items-center justify-center relative right-8 h-[50px] w-[50px]'>
                <CiSearch className='text-white text-[25px] font-semibold' />
              </div>
              </div>
              {isDropdownVisible && (
                <div className="absolute z-50 border-gray-200 rounded-md w-full shadow-lg search-wrapper">
                  {searchResults.length > 0 ? (
                    searchResults.map((product) => (
                      <div key={product.id} className="p-2 hover:bg-gray-300 m-2 cursor-pointer">
                        <Link to={`/product-details/${product.product_slug}`} onClick={() => onHandleClick(product)} className='p-1'>
                          {product.title}
                        </Link>
                      </div>
                    ))
                  ) : (
                    <p className="p-2 text-gray-600">No results found</p>
                  )}
                </div>
              )}
            </div>
            <div className='col-span-3 gap-2 items-center justify-end flex flex-row pl-2 text-left login-col'>
              

               {userDetails && (
                <Link to='/order-history' className='order-history-link'>
                              <CiShoppingCart className='cart-icon' />
                              Inquiry Basket</Link>
               )}
             

              {token && (
                <button className='logout-btn' onClick={handleLogout}>
                <CiPower className='logout-icon' />
                Logout</button>
              )}

              {!token && (
                <>
                  <button className='px-4 h-10 bg-white text-[#082f4a] font-[400]  '>
                    <Link to="/login">Login</Link>
                  </button>
                  <button className='bg-[#CF2532] mr-2 px-4 h-10   text-white rounded-md'>
                    <Link to="/register">Sign UP</Link>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="main-menu">
      <div className='container flex'>

    
      <div className="all-cate-menu">
              <button className="all-cat-menu-btn">
              <span className='flex'>            
              <CiBoxList className='text-white text-[20px] font-semibold mr-[5px]' />
              All Categories</span>
              <div className='all-menu-hover'>
              <CategoryList/>
              </div>
              </button>
             
      </div>
      <ul className="static-top-menu">
        <li><Link to="/about">About Us</Link></li>
        <li><a href="https://mci.gov.tl/" target="_blank">MCI Website</a></li>
        <li><a href="https://www.timor-lestetradeportal.com/en-gb" target="_blank">TIP</a></li>
        <li><Link to="/gallery">Gallery</Link></li>
      </ul>

      <div className='mobile-login-col'>
              

              {userDetails && (
               <Link to='/order-history' className='order-history-link'>
                             <CiShoppingCart className='cart-icon' />
                             Inquiry Basket</Link>
              )}
            

             {token && (
               <button className='logout-btn' onClick={handleLogout}>
               <CiPower className='logout-icon' />
               Logout</button>
             )}

             {!token && (
               <>
                 <button className='px-4 h-10 bg-white text-[#082f4a] font-[400]  '>
                   <Link to="/login">Login</Link>
                 </button>
                 <button className='bg-[#CF2532] mr-2 px-4 h-10   text-white rounded-md'>
                   <Link to="/register">Sign UP</Link>
                 </button>
               </>
             )}
           </div>
      {/* <CategoryList/> */}
      </div>

      <button className="close-menu-btn" onClick={closeMenu}>
            <CiCircleRemove className="text-white text-[30px] font-semibold" />
          </button>
      </div>
    </div>
  );
}

export default Header;
