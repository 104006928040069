import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Products from './Components/Products';
import Login from './pages/Login';
import Register from './pages/Register';
import Product from './pages/Product';
import Home from './pages/Home'
import ProductDetails from './pages/ProductDetails';
import EmailVerificationSend from './pages/EmailVerificationSend';
import EmailVerification from './pages/EmailVerification';
import OrderHistory from './pages/OrderHistory';
import ForgetPassword from './pages/ForgetPassword';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Return from './pages/Return';
import Shipping from './pages/Shipping';

function App() {

  const checkIfUserIsLoggedIn = () => {
    return localStorage.getItem('user') !== null;

  }
  const isLoggedIn = checkIfUserIsLoggedIn();

  return (
    <div className="App">
      {/* <Home/> */}
      <Router>
        <Routes>
          <Route path='register' element={
            isLoggedIn ? <Navigate to="/" />:
            <Register />} />
          <Route path='/login' element={isLoggedIn ? <Navigate to="/" />: <Login />} />
          <Route index element={<Home />} />
          <Route path='/products' element={<Product/>}/> 
          <Route path= '/products/:slug' element={<Product/>}/>
          <Route path='/product-details/:slug' element={<ProductDetails/>}/>
          <Route path = '/email-verification-send' element={<EmailVerificationSend/>}/>
          <Route path = '/email-verification' element={<EmailVerification/>}/>
          <Route path='/order-history' element={<OrderHistory/>} />
          <Route path= '/forget-password' element={<ForgetPassword/>}/> 
          <Route path= '/about' element={<About/>}/> 
          <Route path= '/gallery' element={<Gallery/>}/> 
          <Route path= '/contact' element={<Contact/>}/> 
          <Route path= '/return' element={<Return/>}/> 
          <Route path= '/shipping' element={<Shipping/>}/> 
        </Routes>
      </Router>

      {/* <Products /> */}
    </div>
  );
}

export default App;
