import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'


function Shipping() {
return (
    <div>
  <div>   
      <Header/>
    <div className='about-container container'>
      {/* <h2 className='page-title'>About Us</h2> */}
      <h3 className='sub-page-title'>Coming Soon</h3> 
      
    </div>
      <Footer/>
    </div>
    </div>
)

}

export default Shipping;
