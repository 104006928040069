import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import OrderHistoryShow from '../Components/OrderHIstoryShow'

function OrderHistory() {
return (
    <div>
  <div>
      {/* <Header/> */}
      <Header/>
    <div className='mx-auto lg:max-w-7xl lg:px-4 xl:max-w-[1600px]'>
      <h2 className='page-title'>Enquiry</h2>
      <h3 className='sub-page-title'>Below is your enquiry list</h3>
   <OrderHistoryShow/>
    </div>
      <Footer/>
    </div>
    </div>
)

}

export default OrderHistory;
