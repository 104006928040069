import React, {useEffect} from 'react'

import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ProductDetail from '../Components/ProductDetail'

import ProductShow from '../Components/ProductShow'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../Components/Breadcrumb'
import { useLocation } from 'react-router-dom'
  
function ProductDetails() {
  const { slug } = useParams();
  const location = useLocation();
  const breadcrumb = location.state?.breadcrumbs ?? [];
  console.log('breadcrumb_detail:', breadcrumb);

  const breadcrumbs = [
    //  { name: 'Home', path: '/' },
    //  { name: 'Products', path: '/products' },
    //  { name: slug, path: `/products/${slug}` }
    ...breadcrumb
    ];

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [slug])
  // const breadcrumb = breadcrumb;
return (
    <div>
  <div>
      {/* <Header/> */}
      <Header/>
      <Breadcrumb breadcrumbs={breadcrumbs} />
    <div className='mx-auto lg:max-w-7xl lg:px-4 xl:max-w-[1600px]'>
   <ProductDetail slug = {slug}/>
  <ProductShow/>   


    </div>




      <Footer/>
      
    </div>


    </div>
)

}

export default ProductDetails;
