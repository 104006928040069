import React, {useState} from 'react';
import postbanner from '../assests/img/post-3.png';

const PostYourRequirment = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    query: "",
  });

  const [errorText, setErrorText] = useState('');

  const [enquirySubmittedText, setEnquirySubmittedText] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(submitting) return;
    setErrorText('');
    setEnquirySubmittedText('');
    if (input.name == "" || input.email == "" || input.query == "") {
      setErrorText('Please fill all the fields');
      return;
    }
    console.log(input);
    setSubmitting(true);

    const submitUrl = `https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/customerenquirieshome?email=${input.email}&name=${input.name}&enquiry=${input.query}`;

    var response = await fetch(submitUrl);
    var data = await response.json();
    setSubmitting(false);

    if (data.status == 'true') {
      setEnquirySubmittedText('Enquiry submitted successfully');
      setInput({
        name: "",
        email: "",
        query: "",
      });

    }
  }
  return (
    <div>
     <div className='formbox-container flex'>
        <div className='w-1/2 form-left'>
        <img src={postbanner} alt="Logo" className="w-full" />
        </div>


        <div className='form-right w-1/2'>
        <h2>Post Buy Requirement</h2>
        <h3>Tell us what you need, and we'll help you get quotes</h3>
        <form className='enquiry-form' onSubmit= {handleSubmit}>

          <div className='form-col'>
          <label for="username" className="block text-sm font-medium leading-6 text-gray-900">Provide the below details to get quick quotes from sellers*</label>
          <div className="mt-2">           
              <input type="text" name="name" id="username" autocomplete="username" className="" placeholder="Enter full name.." value={input.name} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} />           
          </div>
          </div>

          

          {/* <div className='form-col'>
          <div className='flex'>
          <div className="mt-2 w-1/2 mr-2">           
              <input type="text" name="username" id="username" autocomplete="username" className="" placeholder="Quantity"/>           
          </div>
          <div className="mt-2 w-1/2">           
              <input type="text" name="username" id="username" autocomplete="username" className="" placeholder="Unit"/>           
          </div>

          </div> */}
          {/* </div> */}

          <div className='form-col'>
          <div className='flex'>         
          <div className="mt-2 w-full">           
              <input type="email" name="email" id="username" autocomplete="username" className="" placeholder="Enter the Email Address" value={input.email} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} />           
          </div>

          </div>
          </div>

          <div className="mt-2">           
              <input type="text" name="query" id="enqyiry" autocomplete="enquiry" className="" placeholder="Enter product description.." value={input.query} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} />           
          </div>

          <span className='text-red-500'>{errorText}</span>

          <span className='text-green-500'>{enquirySubmittedText}</span>

          <div className='form-col'>
          <div className='flex'>
          <div className="mt-2 w-full">           
              <button onSubmit= {handleSubmit} className='post-btn'>{
                submitting ? "Submitting..." : "Submit"}</button>           
          </div>

          </div>
          </div>





        </form>          
        </div>
     </div>

    </div>
  )
}

export default PostYourRequirment
