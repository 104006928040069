import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import logo from '../assests/img/logo.png';
import { NavLink, Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import NewArrival from '../Components/NewArrival';

const ForgetPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [input, setInput] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorText('');
    if (input.email == "" ) {
      setErrorText('Please fill all the fields');
      return;
    }
    if (loading) return;
    setLoading(true);
    const loginUrl = `https://madeintimorleste.com/seller/wp-json/makeittomer-api/v1/forgetPassword?email=${input.email}`;
    var response = await fetch(loginUrl);
    var data = await response.json();
    if (data.status == 'true') {
 
    } else {
      setErrorText(data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className='login-container'>
        <div className='login-form-box'>
          <form onSubmit={handleSubmit} className='flex flex-col gap-7 forgot-form rounded-md'>
            <h1 className='login-title'>Forgot Password</h1>
            <p>Please Enter your registered Email Id to get Password reset link</p>
            <input name='email' value={input.email} onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} type="email" placeholder='Enter your Email' className='input-box' />
            <span className='text-red-500'>{errorText}</span>
            <button type='submit' className='login-btn'>{loading ? 'Loading..' : 'Submit'}</button>
          </form>
        </div>

        <div className='login-advt'>
          <NewArrival/>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ForgetPassword