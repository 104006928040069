import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import advt1 from '../assests/img/advt-01.jpeg';
import advt2 from '../assests/img/advt-02.jpeg';
import advt3 from '../assests/img/advt-03.jpeg';
import advt4 from '../assests/img/advt-04.jpeg';
import advt5 from '../assests/img/advt-05.jpeg';
import advt6 from '../assests/img/advt-06.jpeg';
import advt7 from '../assests/img/advt-07.jpeg';
import advt8 from '../assests/img/advt-08.jpeg';
import advt9 from '../assests/img/advt-09.jpeg';
import advt10 from '../assests/img/advt-10.jpeg';
import advt11 from '../assests/img/advt-11.jpeg';
import advt12 from '../assests/img/advt-12.jpeg';
import advt13 from '../assests/img/advt-13.jpeg';


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  

const NewArrival = () => {
  return (
    <div>
      <div className="w-full  ">
            <Carousel responsive={responsive}
              autoPlay={true}
              infinite={true}>
              
              <div><div className='advt-slide'> 
                <img src={advt1} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt2} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt3} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt4} alt='madeintimorleste'/>
              </div></div>
              
           
              <div><div className='advt-slide'> 
                <img src={advt6} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt7} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt8} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt9} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt10} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt11} alt='madeintimorleste'/>
              </div></div>

              <div><div className='advt-slide'> 
                <img src={advt12} alt='madeintimorleste'/>
              </div></div>
           
            </Carousel>
           
          </div>
    </div>
  )
}

export default NewArrival
