import React from 'react';
import { Link } from 'react-router-dom';

const Category = ({ category }) => {
  const renderSubcategories = (subcategory) => {
    const subcategoryKeys = Object.keys(subcategory).filter(key => !isNaN(key));

    if (subcategoryKeys.length === 0) {
      return null;
    }

    return (
      <ul className="subcategory-ul">
        {subcategoryKeys.map(key => (
          <Category key={subcategory[key].category_id} category={subcategory[key]} />
        ))}
      </ul>
    );
  };

  const handleClick = () => {
    console.log('Category clicked:', category.category_name);
  };

  const hasSubcategories = Object.keys(category).some(key => !isNaN(key));

  return (
    <li key={category.category_id} className='mb-2'>
      <h2 className='mb-2'>
        {
        // hasSubcategories ? (
        //   category.category_name
        // ) : 
        (
          <Link 
          to={`/products/${category.slug}`}
          state={{ 
            breadcrumb: [
              { name: 'Home', path: '/' },
              { 
                id: category.id, 
                name: category.category_name, 
                path: `/products/${category.slug}` 
              }
            ]
          }}
        >
          {category.category_name}
        </Link>
        )
        }
      </h2>
      {hasSubcategories && renderSubcategories(category)}
    </li>
  );
};

export default Category;
