import React from 'react';
import PostBanner1 from '../assests/img/home-banner-01.jpeg';
import PostBanner2 from '../assests/img/home-banner-02.jpeg';
import PostBanner3 from '../assests/img/home-banner-03.jpeg';


const PopularCategory = () => {
  return (
    <div>
      <div className='popular-row'>
     
        <div className='cat-box'>
        <div className='cat-box-inner'>
          <a href="https://timor-lestetradeportal.com/en-gb" target="_blank">
            <img alt="MadeinTimorleste" title="MadeinTimorleste" src={PostBanner1} />
          </a>
            
        </div>        
        </div>        
       
        <div className='cat-box'>
        <div className='cat-box-inner'>
            <a href="https://timor-lestetradeportal.com/en-gb" target="_blank">
              <img alt="MadeinTimorleste" title="MadeinTimorleste" src={PostBanner2} />
            </a>
        </div>
        </div>       
       
        <div className='cat-box'>
        <div className='cat-box-inner'>
            <a href="https://timor-lestetradeportal.com/en-gb" target="_blank">
            <img alt="MadeinTimorleste" title="MadeinTimorleste" src={PostBanner3} />
            </a>
        </div>
        </div> 

      </div>

    </div>
  )
}

export default PopularCategory
